import React from "react";
import challengeData from 'data/SingleProject/challenge.json'
import challengeDataRTL from 'data/SingleProject/challenge-rtl.json'

const Challenge = ({ style = "4", rtl }) => {
  const data = rtl ? challengeDataRTL : challengeData;
  return (
    <div className="challenge section-padding style-4 overflow-hidden">
      <div className="container">
        <div className="row gx-5">
          <div className="col-lg-4">
            <div className={`section-head style-4`}>
              <h2>{ rtl ? '' : 'Asset' } <br /> <span>{  'Finance' }</span> </h2>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="inf">
              <div className="text color-666 mb-20">
                {  'Are you looking to wind down on high costs of fuel and electricity using renewable solar energy?' }
              </div>
              <div className="text color-666 mb-20">
                {  'Say hello to our superb solar-asset finance plan that offers you easy access to uninterrupted and affordable renewable solar energy for your homes, shops, and offices.' }
              </div>
              <ul className="color-000">
                {
                  data.challenge.map((item, index) => (
                    <li className="d-flex mb-10" key={index}>
                      <i className="bi bi-dot fs-6 me-2"></i> { item }
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
        </div>
        <div className="imgs mt-60 mb-100">
          <div className="row">
            <div className="col-lg-6">
              <div className="img img-cover rounded-3 overflow-hidden mb-30 mb-lg-0">
                <img src="/assets/img/single_project/ch_1.png" alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img img-cover rounded-3 overflow-hidden">
                <img src="/assets/img/single_project/Mesa-de-trabajo-1.webp" alt="" />
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  )
}

export default Challenge