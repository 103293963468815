import React, { useEffect, useRef } from 'react';
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from 'layouts/Main';
//= Components
import TopNav from 'components/Navbars/TopNav-7';
import Navbar from 'components/Navbars/ITCreativeNav';
import Challenge from 'components/SingleProject/Challenge';
import Footer from 'components/Saas/Footer';
import SideMenu from 'components/ITCreative/SideMenu';

const AssetFinance = () => {
  const navbarRef = useRef(null);

  useEffect(() => {
    navbarScrollEffect(navbarRef.current, true);
  }, [navbarRef]);

  return (
    <MainLayout>
      {/* <TopNav style="5" /> */}
      <SideMenu />
      <Navbar navbarRef={navbarRef} />
      <main className="single-project pt-50 style-5">
     
        <Challenge style="5" />
        
       
      </main>
      <Footer noWave />
    </MainLayout>
  )
}

export const Head = () => {
  return (
    <>
      <title>Asset Finance</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  )
}

export default AssetFinance;